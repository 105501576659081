import React from 'react';
import PropTypes from 'prop-types';
import camelcaseKeys from 'camelcase-keys';

import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import AutoSuggestInput from '@style-guide/components/AutoSuggestInput';
import Select from '@style-guide/components/Select';
import LinkButton from '@style-guide/components/LinkButton';
import Input from '@style-guide/components/Input';

import falSearchParamBuilder from '@services/SearchParamBuilder';
import Divider from '@components/Divider';

import ButtonContainer from './components/ButtonContainer';
import InputRow from './components/InputRow';
import SearchWrapper from './components/SearchWrapper';
import SelectRow from './components/SelectRow';
import Subtitle from './components/Subtitle';
import Title from './components/Title';
import Wrapper from './components/Wrapper';

class FindALawyerSearchBlock extends React.Component {
  constructor(props) {
    super(props);

    const {
      locationFilters,
      areaOfLawFilters,
      practiceAreaFilters,
    } = props;

    this.locationOptions = camelcaseKeys(locationFilters || []).map(filter => filter.filterId);

    this.areaOfLawOptions = camelcaseKeys(areaOfLawFilters || []).map(filter => ({
      label: filter.filterName,
      value: filter.filterId,
    }));

    this.practiceAreaOptions = camelcaseKeys(practiceAreaFilters || []).map(filter => ({
      label: filter.filterName,
      value: filter.filterId,
    }));

    this.areaOfLawOptions.unshift({ label: 'Select an area of law...', value: '' });
    this.practiceAreaOptions.unshift({ label: 'Select a practice area...', value: '' });

    this.state = {
      keyword: '',
      locations: [],
      practiceAreas: [],
      areasOfLaw: [],
      selectedAreaOfLaw: '',
      selectedPracticeArea: '',
    };

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.buildUrl = this.buildUrl.bind(this);
  }

  handleLocationChange(value) {
    if (this.locationOptions.indexOf(value) !== -1) {
      this.setState({
        locations: [{
          filterName: encodeURIComponent(value),
          filterId: encodeURIComponent(value),
        }],
      });
    }
  }

  handleDropdownChange(event) {
    // get the node of the selected option
    const optionNode = event.target.options[event.target.selectedIndex];
    if (event.target.getAttribute('name') === 'practiceAreas') {
      this.setState({ selectedPracticeArea: optionNode.value });
    } else {
      this.setState({ selectedAreaOfLaw: optionNode.value });
    }
    // the filterName is the text of the option element
    // and the value is the value that comes across
    this.setState({
      [event.target.getAttribute('name')]: [{
        filterName: encodeURIComponent(optionNode.label),
        filterId: optionNode.value,
      }],
    });
  }

  handleTextInputChange(field, value) {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState);

      newState[field] = value;

      return {
        ...newState,
      };
    });
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      window.location.assign(this.buildUrl());
    }

    return undefined;
  }

  buildUrl() {
    const { findALawyerUrl } = this.props;
    const {
      keyword,
      locations,
      practiceAreas,
      areasOfLaw,
    } = this.state;
    const searchParams = falSearchParamBuilder({
      keyword,
      locations,
      practiceAreas,
      areasOfLaw,
    });

    return `${findALawyerUrl}${searchParams}`;
  }

  render() {
    const {
      title,
      subTitle,
      buttonLabel,
      areaOfLawFilters,
      practiceAreaFilters,
    } = this.props;

    return (
      <Wrapper>
        <YellowLineSVG />
        <Title>{title}</Title>
        <Subtitle>{subTitle}</Subtitle>
        <SearchWrapper>
          <InputRow>
            <Input
              type="text"
              placeholder="Keyword"
              onChange={e => this.handleTextInputChange('keyword', e.target.value)}
              onKeyPress={this.handleKeyPress}
              id="findALawyerSearchBlockKeyword"
            />
            <AutoSuggestInput
              id="findALawyerSearchBlockLocation"
              placeholder="Location"
              type="text"
              reduxInput={{
                onChange: this.handleLocationChange,
              }}
              onKeyPress={this.handleKeyPress}
              suggestions={this.locationOptions}
              onlySuggestedValuesAllowed
            />
          </InputRow>
          <Divider
            margin="15px 0 0"
            hasDecorator={false}
          />
          <SelectRow>
            {areaOfLawFilters && (
              <Select
                id="findALawyerSearchBlockAreasOfLaw"
                name="areasOfLaw"
                value={this.state.selectedAreaOfLaw}
                placeholder="Select area of law"
                onChange={this.handleDropdownChange}
                onKeyPress={this.handleKeyPress}
                options={this.areaOfLawOptions}
              />
            )}
            {practiceAreaFilters && (
              <Select
                id="findALawyerSearchBlockPracticeAreas"
                name="practiceAreas"
                value={this.state.selectedPracticeArea}
                placeholder="Select practice area"
                onChange={this.handleDropdownChange}
                onKeyPress={this.handleKeyPress}
                options={this.practiceAreaOptions}
              />
            )}
          </SelectRow>
        </SearchWrapper>
        <ButtonContainer>
          <LinkButton href={this.buildUrl()}>
            {buttonLabel}
          </LinkButton>
        </ButtonContainer>
      </Wrapper>
    );
  }
}

FindALawyerSearchBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  findALawyerUrl: PropTypes.string,
  areaOfLawFilters: PropTypes.arrayOf(PropTypes.shape({
    FilterName: PropTypes.string.isRequired,
    FilterId: PropTypes.string.isRequired,
  })),
  practiceAreaFilters: PropTypes.arrayOf(PropTypes.shape({
    FilterName: PropTypes.string.isRequired,
    FilterId: PropTypes.string.isRequired,
  })),
  locationFilters: PropTypes.arrayOf(PropTypes.shape({
    FilterName: PropTypes.string.isRequired,
    FilterId: PropTypes.string.isRequired,
  })),
};

FindALawyerSearchBlock.defaultProps = {
  buttonLabel: 'Find a Lawyer',
  findALawyerUrl: '',
  practiceAreaFilters: [],
  areaOfLawFilters: [],
  locationFilters: [],
};

FindALawyerSearchBlock.displayName = 'FindALawyerSearchBlock';

export default FindALawyerSearchBlock;
